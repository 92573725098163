$(document).ready(function () {
  //slider mainPageSlider
  $(".mainPageSlider").owlCarousel({
    items: 1,
    lazyLoad: true,
    autoplayHoverPause: true,
    loop: true,
    mouseDrag: false,
    margin: 10,
    autoplay: true,
    autoplayTimeout: 4000,
    smartSpeed: 1250,
    nav: true,
    dots: true,
    navText: [$(".owl-left"), $(".owl-right")],
  });
  //end slider mainPageSlider



  //slider photoSlider
  $(".photoSlider").owlCarousel({
    items: 1,
    lazyLoad: true,
    loop: true,
    margin: 10,
    autoplay: true,
    autoplayTimeout: 4000,
    smartSpeed: 1250,
    nav: false,
    dots: true,
    //navText: [$('.owl-left'),$('.owl-right')]
  });
  //end slider photoSlider

  //animacja wejscia dla stron
  //document.body.classList.add('fadeIn');

  //header scroll fixed
  $(window).scroll(function () {
    if ($(window).scrollTop() >= 200) {
      $(".fixedHeader").addClass("active");
    } else {
      $(".fixedHeader").removeClass("active");
    }
  });
  //end header scroll fixed

  //zmiana wygladu headera gdy nie jest wlaczony slider
  if ($(".mainSlider").length === 0) {
    // $("header").css("background-color", "rgba(0,45,114,.7)");
    // $(".dropdown-menu").css("background-color", "rgba(0,45,114,.7)");
    $("body").css("padding-top", "100px");
  }
  //

  /// klikalne dropdow
  // $('.dropdown > a').click(function(e){
  //   var url = e.target.getAttribute('href');
  //   window.location.href = url;
  // })
  ///

  //burger menu
  $(".burger").click(function () {
    $(this).toggleClass("active");
    $(".nav").slideToggle("fast");
  });
  //end burger menu

  //pokaz formularz rozwijany konferencje
  $(".specialForm .showFormPanel img").click(function () {
    $(".formConference .formWrap").slideToggle();
  });
  //end pokaz formularz rozwijany konferencje

  //preloader booking rezerwacja
  // $('#booking_frame').load(function () {
  // 	setTimeout(function() {
  // 		$('#booking_frame').css('background-color', '#fff');
  // 	}, 4000);
  // });

  //slider hotel-slider
  $(".hotel-slider").slick({
    infinite: true,
    speed: 900,
    lazyLoad: "ondemand",
    centerMode: true,
    variableWidth: true,
    autoplay: true,
    autoplaySpeed: 2000,
  });

  $(".opinions-slider").slick({
    infinite: true,
    speed: 900,
    slidesToShow: 1,
    autoplay: true,
    arrows: true,
    autoplaySpeed: 3000,
    dots: true,
    margin: 25,
    padding: 25,
    // responsive: [
    //     {
    //       breakpoint: 1150,
    //       settings: {
    //         slidesToShow: 3
    //       }
    //     },
    //     {
    //       breakpoint: 992,
    //       settings: {
    //         slidesToShow: 2
    //       }
    //     },
    //     {
    //       breakpoint: 576,
    //       settings: {
    //         slidesToShow: 1
    //       }
    //     }
    //   ]
  });

  //panelMobileBottom
  var lastScrollTop = 0;
  $(window).scroll(function (event) {
    if (window.screen.width <= 768) {
      var st = $(this).scrollTop();
      if (st > lastScrollTop) {
        // downscroll code
        $(".mobileBottomPanel").slideDown();
      } else {
        // upscroll code
        //$('.mobileBottomPanel').stop().slideUp()
      }
    } else {
      $(".mobileBottomPanel").stop().slideUp();
    }
  });

  //pozycja dla kalendarza
  function topCalendar() {
    var offset = $(".reservationPanel").offset().top;
    var heightPanel = $(".reservationPanel").height();
    var calendarHeight = 271;
    //var mainOffset = offset + heightPanel - 200;


    if ($(window).width() < 576) {
      var mainOffset = offset;
    } else {
      var mainOffset = offset - 160;
    }

    $("#calroot").css("top", mainOffset);
  }

  topCalendar();

  // openReservationPanel

  $(".openReservationPanel").click(function () {
    $(".reservationPanelWrap").slideToggle().promise().done(topCalendar);
  });

  // end openReservationPanel

  $(window).resize(function () {
    topCalendar();
  });

  $(window).scroll(function () {
    topCalendar();
  });
  // end pozycja dla kalendarza

  //menu boczne

  $(".burgerIcon").click(function () {
    console.log("burgerIcon click");
    $(".slideNav").toggleClass("active");
  });

  if ($(".mainSlider").length === 0) {
    $("header").css("background-color", "#0f3959");
    $(".page").addClass("noSlider");
  }

  $(".twoRowsSlider").slick({
    rows: 2,
    dots: false,
    arrows: true,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          rows: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          rows: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 1,
        },
      },
    ],
  });
});
